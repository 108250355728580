.single-room {
  .amenities-list {
    flex-wrap: wrap;
    height: 14rem;
    margin-bottom: 4rem;
  }
}

.pic-container {
  margin: auto;
  width: 100%;
  border: 1px solid $light-grey;
  border-radius: 8px;
  img {
    display: block;
    margin: 0 auto;
    height: 26.7rem;
  }
}

.tiny-pic {
  margin: 0 4rem 2rem 0;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  img {
    width: 5rem;
    height: auto;
  }
}
