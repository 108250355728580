// Set box-sizing globally
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

// Ensure the app containers fills the screen
html,
body,
#root {
  min-height: 100vh;
  background: #deecff;
}

// Set the font-size so 1rem = 10px for easier styling
html {
  font-size: 10px;
}

p,
a,
input,
label,
h1,
h2,
h3,
h4 {
  font-family: "Asap", sans-serif;
}

p,
a,
input,
label {
  font-size: 1.4rem;
}

a, p {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

a:hover, p:hover {
  color: inherit;
}

input,
label {
  display: block;
}

input {
  margin-bottom: 1.6rem;
  height: 4rem;
  width: 24rem;
}

input, textarea {
  border: 1px solid lightslategray;
  border-radius: .4rem;
  padding: 0 .8rem;

}

textarea {
  width: 50rem;
  height: 20rem;
  margin-bottom: 1.6rem;
  resize: none;
}

//colors
$blue: #5d5dec;
$black: rgba(0, 0, 0, 0.87);
$white: #fff;
$light-grey: lightslategrey;
