
.resource_holder {
    margin-top: 5.6rem;
}
.resource_card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0.5rem;
  padding: 0.8rem 0.8rem 0.8rem 1.6rem;
  width: 38rem;
  margin-bottom: 2.4rem;

  &.wider {
      width: 100%
  }

  .underline {
    text-decoration: underline;
  }

  .pdf_link {
      margin-right: 4rem;
      margin-bottom: 1em;
  }

  h4 {
      color: $blue;
  }

  img {
      width: 12rem;
      height: 16rem;
  }
}

.resource_label {
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 0.5rem;
    padding: 0.8rem 0.8rem 0.8rem 1.6rem;
    width: 16rem;

    h4 {
        color: $blue;
        text-align: center;
        margin-bottom: 0;
    }
}
