form {
  // border: 1px solid lightslategrey;
  padding: 2rem 4rem;
  // border-radius: .4rem;
  // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2)
}

.send-button {
  width: 50rem;
  height: 4rem;
  color: $white;
  background-color: $blue;
  border-radius: .4rem;
  border: none;
}