.login {
  padding-top: 20rem;

  form {
    padding: 8rem 2rem 2rem 2rem;
    width: 60rem;
    border-radius: 0.4rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    button {
      width: 100%;
    }
  }
}
