.room-list {
  min-height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .hide {
    order: 5;
  }
  .disable {
    order: 5;
    visibility: hidden;
  }
  .room-card {
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 1.6rem;
    padding: 1.6rem;
    .room-pic {
      width: 25rem;
    }
  }
}

.book-button {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 24rem;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 4px 8px;
  color: $white;
  background-color: $blue;
  border-radius: 8px;
  margin: 4.8rem auto;
  &:hover {
    color: $white;
  }
}

.copy-button {
  height: 4rem;
  margin: 0 1.6rem;
}

.copy-link-box {
  margin-bottom: 1.6rem;
}
