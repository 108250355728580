footer {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding: .8rem 0 .8rem 0;

    a {
      font-size: 1.6rem;
    }
  
    nav {
      .navLink {
        font-weight:500;
        padding: 4px 8px;
        &.book {
          color: $white;
          background-color: $blue;
          border-radius: 8px;
          margin: 0 8px;
        }
        &.explore {
          font-style: italic;
          font-weight: 700;
          color: $blue;
        }
      }
    }
    .login-box {
      width: 25%;
      text-align: right;
      padding-right: 4rem;
    }
  }